import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ProfileListCard.css";

function ProfileListCard({ icon, list_Name, IC }) {
  return (
    <>
      {/* Recommended Product */}
      <div className="row Plist mt-4">
        <div className="col-3 d-flex align-items-center justify-content-center">
          <FontAwesomeIcon icon={icon} style={{ color: IC }} />
        </div>
        <div className="col-9 d-flex">
          <div className="LIN">{list_Name}</div>
        </div>
      </div>
      <hr />
    </>
  );
}

export default ProfileListCard;
