import React, { useState,useEffect } from "react";
import "../Home/Home.css";
import Navbar from "../Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import Toolbar from "../ToolBar/Toolbar";
import SearchCard from "../Cards/SearchCard/SearchCard";
import { Carousel } from "react-bootstrap"; // Import Carousel from React Bootstrap
import { getCookie } from "../../utils/cookiesUtils";
function Home() {
  const [isSearchActive, setIsSearchActive] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedCatId = parseInt(queryParams.get("cat_id"));
  const [token, setToken] = useState("token");
  useEffect(() => {
    const token = getCookie("token");
    if (token) {
      setToken(token);
    }
  })
  return (
    <>
      <img className="bgTop" src="/assets/bgTop.png" alt="logo" />
      {/* Navbar */}
      <Navbar />

      <div className="container-fluid px-0">
        {/* section 1 */}
        {/* Auto-scrolling Banner Section */}
        <Carousel interval={3000} className="">
          {window.Banner[0].banner.split(",").map((image, index) => (
            <Carousel.Item key={index}>
              <img
                className="banner d-block w-100"
                src={`assets/Banners/${image}`}
                alt={`Banner ${index + 1}`}
              />
            </Carousel.Item>
          ))}
        </Carousel>

        {/* section 2 */}

        <div className="search mt-3">
          <div className="input-group mb-3">
            <span className="input-group-text" id="search-icon">
              <FontAwesomeIcon icon={faSearch} />
            </span>
            <input
              type="text"
              className="SearchInput form-control"
              placeholder="Search"
              aria-label="Search"
              onFocus={() => setIsSearchActive(true)}
              onBlur={(e) => {
                if (
                  !e.target.className === "SearchInput" &&
                  !e.target.className === "SearchDiv"
                ) {
                  setIsSearchActive(false);
                } else {
                  setTimeout(() => setIsSearchActive(false), 200);
                }
              }}
            />
          </div>
          <div className={`SearchDiv ${isSearchActive ? "active" : ""}`}>
            {window.Product.map((item) => (
              <SearchCard ProName={item.name} />
            ))}
          </div>
        </div>

        {/* section 4 */}
        <div className="container-fluid">
          <div className="row">
            {window.Product.map((item, index) => (
              <div className="col-6 mt-3 ProCard">
                <img src="./assets/CBG.png" className="lineart" alt="" />
                <img src="./assets/CBG.png" className="lineart2" alt="" />

                <Link
                  to={`/Details?product_id=${item.id}`}
                  style={{ textDecoration: "none" }}
                  onClick={() => window.scrollTo(0, 0)}

                >
                  <div className="PCard p-1  justify-content-center">
                    <img
                      className=" "
                      src={`assets/Product/${
                        window.Product[index].img.split(",")[0]
                      }`}
                      alt={`${item.name}`}
                    />
                    <div className="PName">{item.name}</div>
                    <div className="PPrice">Rs. {item.price}</div>
                  </div>
                </Link>
                <div className="ProductCardButton">
                  <img src="./assets/btnbg.png" className="btnArt" alt="" />
                  <img src="./assets/btnbg.png" className="btnArt2" alt="" />
                  <button className="CB">Add To Bag</button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Toolbar />
      <div style={{ height: "60px" }}></div>

      <div hidden>
        <dotlottie-player
          src="https://lottie.host/f14dbc81-7b7c-453e-8132-e0551c85da37/jeZbPAbV28.json"
          background="transparent"
          speed="1"
          style={{ width: "300px", height: "300px" }}
          loop
          autoplay
        ></dotlottie-player>
        <dotlottie-player
          src="https://lottie.host/2d18ea19-5c66-4a0f-9f1f-53684d481a7d/4EN0VWA9OY.json"
          background="transparent"
          speed="1"
          style={{ width: "250px", height: "300px" }}
          direction="1"
          playMode="normal"
          loop
          autoplay
        ></dotlottie-player>
      </div>
    </>
  );
}

export default Home;
