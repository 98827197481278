import "bootstrap/dist/css/bootstrap.min.css";
import {} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import NotificationCard from "../Cards/NotificationCard/NotificationCard";

function Notification() {
  return (
    <>
      <div className="container-fluid mt-2" style={{ fontSize: "20px" }}>
        <Link to={"/Home"} className="text-decoration-none text-black">
          👈 back to home
        </Link>
        <div className="container-fluid px-0 mt-2 mb-2">
          {/* section 2 */}
          <NotificationCard
            Type={"Order #222"}
            Message={"Your order has been placed."}
          />
          <NotificationCard
            Type={"Order #222"}
            Message={"Your order has been placed."}
          />
          <NotificationCard
            Type={"Order #222"}
            Message={"Your order has been placed."}
          />
          <NotificationCard
            Type={"Order #222"}
            Message={"Your order has been placed."}
          />
          <NotificationCard
            Type={"Order #222"}
            Message={"Your order has been placed."}
          />
          <NotificationCard
            Type={"Order #222"}
            Message={"Your order has been placed."}
          />
        </div>
      </div>
    </>
  );
}

export default Notification;
