import "bootstrap/dist/css/bootstrap.min.css";
import "../Home/Home.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import C_Card from "../Cards/C_Card/C_Card";
import Toolbar from "../ToolBar/Toolbar";

function Category() {
  return (
    <>
      <img className="bgTop" src="/assets/bgtop.png" alt="logo" />

      <Navbar />
      <div className="container px-0">
        {/* Breadcrumb Section */}
        <div className="row mt-2 mb-3">
          <div className="col-10">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link id="link" to="/Home">
                  Home{" "}
                </Link>
              </li>

              <li className="breadcrumb-item">
                <Link id="link" to="/Category">
                  Category
                </Link>
              </li>
            </ol>
          </div>
        </div>

        {/* Category List */}
        <div className="container">
          <div className="row">
            {window.Category.map((item, index) => (
              <div key={index} className="col-6">
                {/* Pass the cat_id as a query parameter */}
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/Pro_Page?cat_id=${item.cat_id}`}
                >
                  <C_Card
                    key={index}
                    imgSrc={`assets/Category/${item.img}`}
                    text={item.name}
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div style={{ height: 24 }}>&nbsp;</div>
      <Toolbar />
    </>
  );
}

export default Category;
