import Navbar from "../Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
} from "@fortawesome/free-regular-svg-icons";
import {
  faPencil,
  faLocationDot,
  faCartShopping,
  faPhone,
  faPowerOff,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Toolbar from "../ToolBar/Toolbar";
import "./More.css";
import ProfileListCard from "../Cards/ProfileListCard/ProfileListCard";
import { getCookie } from "../../utils/cookiesUtils";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
function deleteCookie(name) {
  document.cookie = `${name}=; path=/; max-age=0;`;  // Set max-age=0 to delete the cookie
}
const More=() =>{
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    const token = getCookie("token");
    const name = getCookie("name");
    if (token) {
      // Token exists, user is logged in
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  // Handle logout


  return (
    <>
      <Navbar />

      {/* section 1 */}
      <div className="container-fluid px-0">
        <div className="row mt-2">
          <div className="col-10">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link id="link" to="/Home">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link id="link" to="/More">
                  More
                </Link>
              </li>
            </ol>
          </div>
          <div className="col-2">
            <FontAwesomeIcon id="bell" icon={faBell} />
          </div>
        </div>

        {/* section 2 */}
        <div className="row align-items-center Profile  ">
          <div className="col-1 ProfilePic">
            <img src="/assets/profile.jpeg" alt="Profile" />
          </div>
          <div className="col-2"></div>
          <div className="col-8 about ">
            <div className="UserName">User123</div>
            <div className="Phno">03xxxxxxxxx</div>
          </div>
        </div>

        {/* Section 3 */}
        <Link
          to="/EditProfile"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ProfileListCard
            icon={faPencil}
            list_Name={"Edit Profile"}
            IC={"#4A90E2"}
          />
        </Link>
        <Link
          to={"/MyAddress"}
          style={{ textDecoration: "none", color: "black" }}
        >
          <ProfileListCard icon={faLocationDot} list_Name={"My Address"} />
        </Link>
        <Link to={"/Orders"} style={{ textDecoration: "none", color: "black" }}>
          <ProfileListCard icon={faCartShopping} list_Name={"My Orders"} />
        </Link>

        {/* <Link
          to={"/ChatPage"}
          style={{ textDecoration: "none", color: "black" }}
        >
          <ProfileListCard
            icon={faMessage}
            list_Name={"Chat with us"}
            IC={"green"}
          />
        </Link> */}

        <Link
          to="https://wa.me/+923125659460"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ProfileListCard
            icon={faPhone}
            list_Name={"Talk to our Support"}
            IC={"orange"}
          />
        </Link>

        {/* <Link style={{ textDecoration: "none", color: "black" }}>
          <ProfileListCard
            icon={faFacebookF}
            list_Name={"Message to facebook page"}
            IC={"#4D91E5"}
          />
        </Link> */}

        <Link to='/Logout' style={{ textDecoration: "none", color: "black" }}>
          <ProfileListCard icon={faPowerOff} list_Name={"Log out"} IC={"red"} />
          {console.log("logout")}
        </Link>
      </div>
      <Toolbar />
      <Toaster/>
      <div style={{ height: "60px" }}></div>
    </>
  );
}

export default More;
