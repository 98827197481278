import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import "./MyAddress.css";
function AddNewAddress() {
  return (
    <>
      <Navbar />
      <div className="container ">
        <h3 className="d-flex justify-content-center mb-3">Add new Address:</h3>
        <form className="d-flex flex-column justify-content-center align-items-center">
          <textarea className="LOC mx-1 " rows={8} cols={40}></textarea>
          <div className="Time d-flex w-100">
            <button type="button">Office</button>
            <button type="button">Home</button>
            <button type="button">Work</button>
          </div>
          <div className="row d-flex w-100 ">
            <Link className="OrderButton col-6">
              <button
                className="d-flex justify-content-center align-items-center w-100"
                type="button"
              >
                Save
              </button>
            </Link>

            <Link to={"/MyAddress"} className="OrderButton col-6">
              <button
                className="d-flex justify-content-center align-items-center w-100"
                type="button"
              >
                Cancel
              </button>
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddNewAddress;
