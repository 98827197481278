import React, { useState ,useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faCamera, faLock, faPhone } from "@fortawesome/free-solid-svg-icons"; // Ensure correct import
import { Link } from "react-router-dom";
import Toolbar from "../ToolBar/Toolbar";
import "./Edit_Profile.css";
import { getCookie } from "../../utils/cookiesUtils";

function EditProfile() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [name, setName] = useState("name");

  useEffect(() => {
    const token = getCookie("token");
    const name = getCookie("name");
    if (token) {
      // Token exists, user is logged in
      setIsLoggedIn(true);
      setName(name);  // Retrieve the username from cookies
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <>
      <Navbar />
      <div className="container-fluid px-0">
        {/* section 1 */}
        <div className="row ">
          <div className="col-10">
            <ol className="breadcrumb mb-3">
              <li className="breadcrumb-item">
                <Link id="link" to="/Home">
                  Home{" "}
                </Link>
              </li>

              <li className="breadcrumb-item">
                <Link id="link" to="/More">
                  More
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link id="link" to="/EditProfile">
                  EditProfile
                </Link>
              </li>
            </ol>
          </div>
        </div>

        {/* section 2 */}
        <div className="d-flex align-items-center justify-content-center position-relative">
          <img
            className="profile_pic"
            src="/assets/profile.jpeg"
            alt="Profile"
          />
          <button className="edit-button">
            <input className="form-control" type="file" accept="image/*ss" />
            <FontAwesomeIcon icon={faCamera} />
          </button>
        </div>

        {/* section 3 */}
        <div>
          <div className="row Plist mt-1">
            <div className="Eicon col-2 d-flex align-items-center justify-content-center ">
              <FontAwesomeIcon icon={faUser} />
            </div>
            <div className="col-10 d-flex flex-column">
              <label>Full Name</label>
              {name}
            </div>
          </div>
          <hr />

          <div className="row Plist ">
            <div className="Eicon col-2 d-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={faLock} />
            </div>
            <div className="col-10 d-flex flex-column">
              <label>Password</label>
              <input
                type="password"
                className="Eprofile form-control p-2"
                placeholder="Enter password"

              />
            </div>
          </div>
          <hr />

          <div className="row Plist ">
            <div className="Eicon col-2 d-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={faPhone} />
            </div>
            <div className="col-10 d-flex flex-column">
              <label>Phone Number</label>
              <input
                type="tel"
                className="Eprofile form-control p-2"
                placeholder="Enter phone number"
              />
            </div>
          </div>
          <hr />
          <div>
            <Link className="OrderButton" to="/More">
              <button>Save</button>
            </Link>
          </div>
        </div>
        {/* section 4 */}
      </div>
      <Toolbar />
      <div style={{ height: "60px" }}></div>
    </>
  );
}

export default EditProfile;
