import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import "../Navbar/Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import { getCookie } from "../../utils/cookiesUtils";

// Function to delete a cookie by name

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [name, setName] = useState("name");
  const navigate = useNavigate();

  // Check login status on component mount
  useEffect(() => {
    const token = getCookie("token");
    const name = getCookie("name");
    if (token) {
      // Token exists, user is logged in
      setIsLoggedIn(true);
      setName(name);  // Retrieve the username from cookies
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  // Handle logout
  // const handleLogout = () => {
  //   // Clear the token and username from cookies
  //   deleteCookie("token");
  //   deleteCookie("username");
  //   navigate("/Email");  // Redirecting to the login page
  //   setTimeout(() => {
  //     toast.success("Logged out successfully!");      
  //   }, 500);
  // };

  return (
    <div className="container p-0">
      <nav className="navbar navbar-expand-lg p-0">
        <Link className="navbar-brand" to="/Home">
          <img id="logo" src="./assets/logo.png" alt="logo" />
        </Link>
            {!isLoggedIn ? (
                <Link className="nav-link" to="/Email">
                  Login
                </Link>
            ) : (
              <>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarColor03"
          aria-controls="navbarColor03"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarColor03">
          <ul className="navbar-nav mx-auto d-flex align-items-center">
              <>
                {/* Display username from window.User if available */}
                <li onClick={() => navigate("/More")}  className="nav-item">
                  <span className="nav-link">
                    Welcome, {name}
                  </span>  {/* Display the username */}
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/Notification">
                    Notification
                  </Link>
                <li className="nav-item">
                  <button className="nav-link btn btn-link" onClick={() => navigate("/Logout")}>
                    Logout
                  </button>
                </li>
                </li>
              </>
          </ul>
        </div>
              </>

            )}
      </nav>
    </div>
  );
};

export default Navbar;
