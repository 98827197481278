// src/SplashScreen.js
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../Splash/Splash.css";

const SplashScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/Home");
    }, 3000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="container-fluid splash-screen">
      <img className="splash-logo" src="/assets/logo.png" alt="logo" />
    </div>
  );
};

export default SplashScreen;
