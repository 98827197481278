import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import Toolbar from "../ToolBar/Toolbar";
import OrderCard from "../Cards/OrderCard/OrderCard";
import "./Orders.css";
function Orders() {
  return (
    <>
      <Navbar />
      {/* section 1 */}

      <div className="container-fluid px-0 mt-2 mb-2">
        <div className="row ">
          <div className="col-10">
            <ol className="breadcrumb ">
              <li className="breadcrumb-item">
                <Link id="link" to="/More">
                  More
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link id="link" to="/Orders">
                  My Orders
                </Link>
              </li>
            </ol>
          </div>
          <div className="col-2">
            <FontAwesomeIcon id="bell" icon={faBell} />
          </div>
        </div>
        {/* Section 2 */}
        <div className="row d-flex">
          <div className="col-6 ">
            <Link className="text-decoration-none" to="/Order2">
              <button className="OrderType align-items-center justify-content-center">
                Order
              </button>
            </Link>
          </div>
          <div className="col-6">
            <Link className="text-decoration-none" to="/Orders">
              <button className="OrderType align-items-center justify-content-center">
                History
              </button>
            </Link>
          </div>
        </div>
        <hr />
        {/* section 3 */}
        <OrderCard
          O_No={"Order No. 1"}
          O_State={"Delivered"}
          O_Date={"12 - 12 - 2022"}
          O_Price={"8000"}
        />
        <OrderCard
          O_No={"Order No. 1"}
          O_State={"Ordered"}
          O_Date={"12 - 12 - 2022"}
          O_Price={"8000"}
        />
        <OrderCard
          O_No={"Order No. 1"}
          O_State={"Cancelled"}
          O_Date={"12 - 12 - 2022"}
          O_Price={"8000"}
        />

        {/* section 3 */}

        {/* section 4 */}
      </div>
      <Toolbar />
      <div style={{ height: "60px" }}></div>
    </>
  );
}

export default Orders;
