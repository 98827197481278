import Sell_Navbar from "./Nav/Nav";
import SHome from "./Home/SHome";
import "./Seller.css";
function Seller() {
  return (
    <div className="Seller m-0 p-0">
      <Sell_Navbar />
      <SHome />
    </div>
  );
}

export default Seller;
