import "bootstrap/dist/css/bootstrap.min.css";
import "../ProPage/ProPage.css";
import Navbar from "../Navbar/Navbar";
import { useLocation, Link } from "react-router-dom";
import Toolbar from "../ToolBar/Toolbar";

function Pro_Page() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedCatId = parseInt(queryParams.get("cat_id"));
  // Filter products based on the selected category ID
  const filteredProducts = window.Product.filter(
    (item) => item.cat_id === selectedCatId
  );

  return (
    <>
      <Navbar />
      <div className="container px-0">
        <div className="row d-flex align-items-center justify-content-between Details">
          <div className="col-auto">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link id="link" to="/Home">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link id="link" to="/Category">
                  Category
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link id="link" to="/Pro_Page">
                  Products
                </Link>
              </li>
            </ol>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            {filteredProducts.length > 0 ? (
              filteredProducts.map((item) => (
                <div className="col-6 mt-3 ProCard" key={item.id}>
                  <img src="./assets/CBG.png" className="lineart" alt="" />
                  <img src="./assets/CBG.png" className="lineart2" alt="" />
                  <Link
                    to={`/Details?product_id=${item.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="PCard p-1 justify-content-center">
                      <img
                        src={`assets/Product/${item.img.split(",")[0]}`}
                        alt={item.name}
                      />
                      <div className="PName">{item.name}</div>
                      <div className="PPrice">Rs. {item.price}</div>
                    </div>
                  </Link>
                  <div className="ProductCardButton">
                    <img src="./assets/btnbg.png" className="btnArt" alt="" />
                    <img src="./assets/btnbg.png" className="btnArt2" alt="" />
                    <button className="CB">Add To Bag</button>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12 text-center">
                <h3 className="mt-3">There are no products in this category</h3>
                <h4>Our other items:</h4>
                <div className="row">
                  {window.Product.map((item, index) => (
                    <div className="col-6 mt-3 ProCard" key={item.id}>
                      <img src="./assets/CBG.png" className="lineart" alt="" />
                      <img src="./assets/CBG.png" className="lineart2" alt="" />
                      <Link
                        to={`/Details?product_id=${item.id}`}
                        style={{ textDecoration: "none" }}
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        <div className="PCard p-1 justify-content-center">
                          <img
                            className=" "
                            src={`assets/Product/${item.img.split(",")[0]}`}
                            alt={item.name}
                          />
                          <div className="PName">{item.name}</div>
                          <div className="PPrice">Rs. {item.price}</div>
                        </div>
                      </Link>
                      <div className="ProductCardButton">
                        <img
                          src="./assets/btnbg.png"
                          className="btnArt"
                          alt=""
                        />
                        <img
                          src="./assets/btnbg.png"
                          className="btnArt2"
                          alt=""
                        />
                        <button className="CB">Add To Bag</button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Toolbar />
    </>
  );
}

export default Pro_Page;
