import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import "./Email.css";
import { APIlogin } from "../../../api/signin";
import toast, { Toaster } from "react-hot-toast"; // Toast for notifications
import { getCookie } from "../../../utils/cookiesUtils";

function Email() {
  const emailRef = useRef(); // Reference for email input
  const passwordRef = useRef(); // Reference for password input
  const [isLoading, setIsLoading] = useState(false); // State to track loading
  const navigate = useNavigate();

  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const ForgetPassword = () => {
    const email = emailRef.current.value;
    const toPage = "NewPass";

    if (!isValidEmail(email)) {
      toast.error("Please enter a valid email!");
      return;
    }

    setIsLoading(true);
    toast.loading("Processing request...", { id: "forgot-loading" });

    setTimeout(() => {
      toast.dismiss("forgot-loading");
      setIsLoading(false);
      navigate("/OTP", { state: { email, toPage } });
    }, 1000); // Simulate an API call
  };

  const validateCredentials = async () => {
    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    if (!email || !password) {
      toast.error("Please fill in all fields!");
      return;
    }

    setIsLoading(true);
    toast.loading("Logging in...", { id: "login-loading" });

    try {
      const login = await APIlogin(email, password);

      if (login.message) {
        document.cookie = `token=${login.user.token}; path=/;`;
        document.cookie = `name=${login.user.username}; path=/;`;
        document.cookie = `email=${login.user.email}; path=/;`;
        const token = getCookie("token");

        toast.dismiss("login-loading");
        toast.success(login.message || "Login successful!");

        if (login.redirect) {
          navigate("/OTP", { state: { email } });
        } else {
          setTimeout(() => {
            navigate("/Home", { state: { email } });
          }, 2000);
        }
      } else {
        toast.dismiss("login-loading");
        toast.error(login.error || "Invalid credentials. Please try again.");
      }
    } catch (error) {
      toast.dismiss("login-loading");
      toast.error("An error occurred during login. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="container-fluid mt-2" style={{ fontSize: "20px" }}>
        <Link to={"/Home"} className="text-decoration-none text-black">
          👈 Back to Home
        </Link>
      </div>
      <div className="container-fluid px-0">
        <div className="d-flex align-items-center justify-content-center">
          <dotlottie-player
            src="https://lottie.host/b2cf5be9-a9c9-4fc0-92a2-1122522e3524/QmQ03jesk9.json"
            background="transparent"
            speed="0.3"
            style={{ width: "250px", height: "250px" }}
            autoplay
          ></dotlottie-player>
        </div>
        <div className="container-fluid">
          <h4>Login</h4>
          <p>Please enter your valid email and password</p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              validateCredentials();
            }}
          >
            <div className="input-group mb-3">
              <span className="input-group-text">
                <FontAwesomeIcon icon={faGoogle} style={{ color: "#4285F4" }} />
              </span>
              <input
                type="email"
                placeholder="Enter your email"
                className="form-control"
                ref={emailRef}
                required
              />
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text">
                <FontAwesomeIcon icon={faKey} />
              </span>
              <input
                type="password"
                placeholder="Enter your password"
                className="form-control"
                ref={passwordRef}
                required
                minLength="8"
                onFocus={(e) => {e.currentTarget.setAttribute("type", "text");}}
                onBlur={(e) => {e.currentTarget.setAttribute("type", "password");}}
  
              />
            </div>
            <button
              type="submit"
              className="Submit btn btn-primary"
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Login"}
            </button>
            <Toaster />
          </form>

          <div className="d-flex align-items-center mt-3">
            <button
              onClick={ForgetPassword}
              className="FPass btn btn-secondary"
              disabled={isLoading}
            >
              {isLoading ? "Processing..." : "Forgot password?"}
            </button>
          </div>

          <Link className="text-decoration-none" to="/NewAcc">
            <div className="d-flex align-items-center justify-content-center mt-3">
              <button className="NewAcc btn btn-success">
                Create new account
              </button>
            </div>
          </Link>
        </div>
      </div>
      <div style={{ height: "60px" }}></div>
    </>
  );
}

export default Email;
