import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import Toolbar from "../ToolBar/Toolbar";
import "./Order2.css";

function Order2() {
  return (
    <>
      <Navbar />
      {/* section 1 */}

      <div className="container-fluid px-0 mt-2 mb-2">
        <div className="row ">
          <div className="col-10">
            <ol className="breadcrumb ">
              <li className="breadcrumb-item">
                <Link id="link" to="/More">
                  More
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link id="link" to="/Orders">
                  My Orders
                </Link>
              </li>
            </ol>
          </div>
          <div className="col-2">
            <FontAwesomeIcon id="bell" icon={faBell} />
          </div>
        </div>
        {/* Section 2 */}
        <div className="row d-flex">
          <div className="col-6 ">
            <Link className="text-decoration-none" to="">
              <button className="OrderType align-items-center justify-content-center">
                Order
              </button>
            </Link>
          </div>
          <div className="col-6">
            <Link className="text-decoration-none" to="/Orders">
              <button className="OrderType align-items-center justify-content-center">
                History
              </button>
            </Link>
          </div>
        </div>
        <hr />
        {/* section 3 */}
        <div className="OrderGIFContainer">
          {/* Correctly use the imported GIF */}

          <dotlottie-player
            src="https://lottie.host/f14dbc81-7b7c-453e-8132-e0551c85da37/jeZbPAbV28.json"
            background="transparent"
            speed="1"
            style={{ width: "300px", height: "300px" }}
            loop
            autoplay
          ></dotlottie-player>
          <h4 class="text-warning fw-bold">🛒 No Orders Yet</h4>
          <p class="text-muted">
            Looks like you haven’t placed any orders. Start exploring and grab
            your favorites now!
          </p>
        </div>
        <div>
          <Link className="OrderButton" to="/Pro_page">
            <button className="">Order now</button>
          </Link>
        </div>
      </div>
      <Toolbar />
      <div style={{ height: "60px" }}></div>
    </>
  );
}

export default Order2;
