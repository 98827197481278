import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import "./ConfirmOrder.css";
import StarRating from "../ConfirmOrder/StarRating";

function ConfirmOrder() {
  return (
    <>
      <Navbar />
      <div className="container-fluid OrderConfirm mt-5">
        <h1>Your Order is Confirmed</h1>
        <div className="d-flex justify-content-center">
          {/* Lottie Player */}
          <dotlottie-player
            src="https://lottie.host/2d18ea19-5c66-4a0f-9f1f-53684d481a7d/4EN0VWA9OY.json"
            background="transparent"
            speed="1"
            style={{ width: "250px", height: "300px" }}
            direction="1"
            playMode="normal"
            loop
            autoplay
          ></dotlottie-player>
        </div>
        <StarRating />
        <div className="Review">
          <textarea
            placeholder="Write a review"
            rows="5"
            cols={"48"}
          ></textarea>
        </div>
        <Link to="/Home">
          <button className="Submit">Submit</button>
        </Link>
        <Link to="/Home">
          <button className="Skip">Skip</button>
        </Link>
      </div>
    </>
  );
}

export default ConfirmOrder;
