import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
function deleteCookie(name) {
  document.cookie = `${name}=; path=/; max-age=0;`; // Set max-age=0 to delete the cookie
}

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    // Delete all relevant cookies
    deleteCookie("token");
    deleteCookie("name");
    deleteCookie("email");

    // Navigate to the Email login page
    navigate("/Email");
    setTimeout(() => {
        toast.success("Logged out successfully!");      
      }, 500);
  }, [navigate]); // Runs once when the component mounts

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <h3>Logging out...</h3>
      <Toaster/>
    </div>
  );
}

export default Logout;
