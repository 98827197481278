// Tool_bar.js
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "../ToolBar/Toolbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faThLarge,
  faShoppingBag,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Toolbar() {
  return (
    <div className="container-fluid toolbar d-flex justify-content-center gap-5">
      <Link to="/Home">
        <button className="toolbar-button">
          <FontAwesomeIcon icon={faHome} className="toolbar-icon" />
        </button>
      </Link>

      <Link to="/Category">
        <button className="toolbar-button">
          <FontAwesomeIcon icon={faThLarge} className="toolbar-icon" />
        </button>
      </Link>

      <Link to="/Cart">
        <button className="toolbar-button">
          <FontAwesomeIcon icon={faShoppingBag} className="toolbar-icon" />
        </button>
      </Link>
      <Link to="/More">
        <button className="toolbar-button">
          <FontAwesomeIcon icon={faBars} className="toolbar-icon" />
        </button>
      </Link>
    </div>
  );
}

export default Toolbar;
