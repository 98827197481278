import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faUser } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./NewPassword.css";
import { useState } from "react";
import { APINewPass } from "../../../api/NewPass";
import toast, { Toaster } from "react-hot-toast";

function NewPassword() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const [isLoading, setIsLoading] = useState(false); // Track loading state
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);

  const handlePasswordChange = (e) => {
    const pwdValue = e.target.value;
    setPassword(pwdValue);
    setSubmitDisabled(
      !pwdValue || pwdValue.length < 8 || confirmPassword !== pwdValue
    );
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPwdValue = e.target.value;
    setConfirmPassword(confirmPwdValue);
    setSubmitDisabled(
      !confirmPwdValue ||
        confirmPwdValue.length < 8 ||
        password !== confirmPwdValue
    );
  };

  const handleNewPass = () => {
    setIsLoading(true);

    APINewPass(email, password)
      .then((res) => {
        setIsLoading(false);

        if (res.message) {
          toast.success("Password updated successfully!");
        }


        if (res.redirect) {          navigate(`/${res.redirect}`);
        } else {
          toast.error(res.error || "An error occurred. Please try again.");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.dismiss("loading");
        toast.error(err.message || "Failed to update password.");
      });
  };

  return (
    <>
      <div className="container-fluid mt-2" style={{ fontSize: "20px" }}>
        <Link to={"/Home"} className="text-decoration-none text-black">
          👈 back
        </Link>
      </div>
      <div className="container-fluid px-0">
        <div className="d-flex align-items-center justify-content-center">
          <dotlottie-player
            src="https://lottie.host/a29b1b22-53d3-48ca-88fb-faca4c762302/qo83s8swRw.json"
            background="transparent"
            speed="1"
            style={{ width: "250px", height: "300px" }}
            loop
            autoplay
          ></dotlottie-player>
        </div>
        <div className="container-fluid mt-4">
          <h3>Enter Your New Password</h3>
          <p>Please enter the password.</p>

          <small className="text-danger">*min length 8 characters</small>
          <div className="input-group mb-3">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faUser} />
            </span>
            <input
              className="form-control"
              value={email}
              type="email"
              disabled
            />
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faKey} />
            </span>
            <input
              type="password"
              id="password"
              placeholder="Enter your password"
              className="form-control"
              required
              minLength="3"
              value={password}
              onChange={handlePasswordChange}
              onFocus={(e) => {e.currentTarget.setAttribute("type", "text");}}
              onBlur={(e) => {e.currentTarget.setAttribute("type", "password");}}            />
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faKey} />
            </span>
            <input
              type="password"
              id="confirm-password"
              placeholder="Confirm your password"
              className="form-control"
              required
              minLength="3"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              onFocus={(e) => {e.currentTarget.setAttribute("type", "text");}}
              onBlur={(e) => {e.currentTarget.setAttribute("type", "password");}}
            />
          </div>
          <button
            className="Submit mt-3 btn btn-primary"
            disabled={isSubmitDisabled || isLoading}
            onClick={handleNewPass}
          >
            {isLoading ? "Processing..." : "Submit"}
          </button>
        </div>
      </div>
      <Toaster/>
      <div style={{ height: "60px" }}></div>
    </>
  );
}

export default NewPassword;
