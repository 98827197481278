import "bootstrap/dist/css/bootstrap.min.css";
import "./ChatPage.css";
import { Link } from "react-router-dom";

function ChatPage() {
  return (
    <div className="container">
      <div className="container-fluid mt-2" style={{ fontSize: "20px" }}>
        <Link to={"/Home"} className="text-decoration-none text-black">
          👈 back to home
        </Link>
        <div className="messages-container mt-4">
          <div className="message receiver">
            <p>Hi! How are you?</p>
          </div>
          <div className="message sender">
            <p>I'm good, thanks for asking!</p>
          </div>
        </div>
        <div className="chat-input-container fixed-bottom">
          <input
            type="text"
            className="chat-input"
            placeholder="Type a message..."
          />
          <button type="button" className="send-button">
            →
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChatPage;
