import "bootstrap/dist/css/bootstrap.min.css";
import "./NotificationCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons"; // Import specific icons
import { useState, useEffect } from "react";

function NotificationCard({ Type, Message }) {
  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
    // Function to update the time
    const updateTime = () => {
      const time = new Date();
      const formattedTime = `${time.getHours()}:${
        time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes()
      }`;
      setCurrentTime(formattedTime);
    };

    // Update the time every minute
    updateTime();
    const intervalId = setInterval(updateTime, 60000);

    // Clean up the interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      {/* Recommended Product */}
      <div className="row OrderNo mt-3">
        <div className="col-10">{Type}</div>
        <div className="col-2 text-end">{currentTime}</div>{" "}
        {/* Show current time */}
      </div>
      <div className="row align-items-center">
        <div className="col-10 d-flex flex-column justify-content-center">
          <div className="Message">{Message}</div>
        </div>

        <div className="Noti col-2 d-flex align-items-center gap-2">
          <FontAwesomeIcon icon={faPencil} />
        </div>
      </div>
      <hr />
    </>
  );
}

export default NotificationCard;
