import "./SHome.css";
function SHome() {
  return (
    <>
      <div className="SHome container-fluid ">
        <div className="row">
          <div className="col-6 SHomeText">
            <h1>Swaad</h1>
            <h3>آپ کے ذائقے کا اصل ساتھی</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Distinctio, reiciendis animi. Hic, porro quia reiciendis, dolorem
              cupiditate harum possimus ex eaque, recusandae praesentium
              accusamus temporibus ab alias. Amet, quasi maxime? Lorem ipsum
              dolor sit amet consectetur adipisicing elit. Distinctio,
              reiciendis animi. Hic, porro quia reiciendis, dolorem cupiditate
              harum possimus ex eaque, recusandae praesentium accusamus
              temporibus ab alias. Amet, quasi maxime? Lorem ipsum dolor sit
              amet consectetur adipisicing elit. Distinctio, reiciendis animi.
              Hic, porro quia reiciendis, dolorem cupiditate harum possimus ex
              eaque, recusandae praesentium accusamus temporibus ab alias. Amet,
              quasi maxime?
            </p>
            <div className="row">
              <div className="col-1 Subimg">
                <img src="./assets/Seller/delivery.png" alt="bike" />
              </div>

              <div className="col-4">
                <h5>Fast Delivery</h5>
                <p>Delivery within 30 minutes</p>
              </div>
              <div className="col-1 Subimg">
                <img src="./assets/Seller/service.png" alt="bike" />
              </div>

              <div className="col-4">
                <h5>Quality Service</h5>
                <p>Delivery within 30 minutes</p>
              </div>
            </div>
          </div>
          <div className="col-6 SHomeImg">
            <img src="./assets/Seller/img.png" alt="fdsf" />
          </div>
        </div>
      </div>
    </>
  );
}

export default SHome;
