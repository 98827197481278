import { Link, useLocation,useNavigate  } from "react-router-dom";
import "./ProductDetail.css";
import Navbar from "../Navbar/Navbar";
import Toolbar from "../ToolBar/Toolbar";
import { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
function Details() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productId = parseInt(queryParams.get("product_id"));

  const product = window.Product.find((p) => p.id === productId);
  const images = product.img.split(","); // Split the images by ','
  // Add state to refresh the page when the product changes
  const navigate = useNavigate();
  const handleAddToCart = () => {
    if (window.Cart) {
      window.Cart.push(product);
      window.scrollTo(0, 0);
    }
    navigate(`/Cart?product_id=${product.id}`);
  };
  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row d-flex align-items-center justify-content-between Details">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link id="link" to="/Home">
                Home{" "}
              </Link>
            </li>

            <li className="breadcrumb-item">
              <Link id="link" to="/Pro_Page">
                Products
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link id="link" to="/Details">
                Product Detail
              </Link>
            </li>
          </ol>
        </div>

        {/* Hero Image */}
        <div className="d-flex justify-content-center PN mt-3">
          {product.name}
        </div>

        <div className="ProductD d-flex justify-content-center align-items-center ">
          <img
            id="HeroImg"
            src={`assets/Product/${images[0]}`}
            alt={product.name}
          />
        </div>

        {/* Additional Images */}

        <div className="sub-image d-flex">
          {images.map((img, index) => (
            <img
              key={index}
              src={`/assets/product/${img}`}
              alt={`Sub product ${index + 1}`}
              className="me-2"
              onClick={() => {
                document.getElementById(
                  "HeroImg"
                ).src = `/assets/product/${img}`;
              }}
            />
          ))}
        </div>

        {/* Quantity and Price */}
        <div className="PP d-flex justify-content-between">
          <p id="quantity">1KG</p>
          <p id="price">{product.price} Rs</p>
        </div>

        {/* Order Button */}
        <div className="PO">
      <button
        className="jello-horizontal w-100"
        style={{ textDecoration: "none" }}
        onClick={handleAddToCart}
      >
        Add to Cart
      </button>
      <Toaster />
    </div>

        {/* Product Description */}
        <div className="PC mt-2">
          <p id="CN">Description</p>
          <p id="CD">{product.desc}</p>
        </div>

        <p id="RN ">You can also check these items:</p>

        {window.Product.map((item) => (
          <Link
            className=""
            to={`/Details?product_id=${item.id}`}
            style={{ textDecoration: "none" }}
            onClick={() => window.scrollTo(0, 0)}
          >
            <div key={item.id} className="row RP">
              {/* Product Image */}
              <div className="col-4">
                <img
                  className="RPI"
                  src={`assets/Product/${item.img.split(",")[0]}`}
                  alt={`${item.name} image`}
                />
              </div>

              {/* Product Details */}
              <div className="col-6 d-flex flex-column justify-content-center">
                <div className="RPN">{item.name}</div>
                <div className="RPSP">
                  <strike>{item.price} PKR</strike>
                </div>
                <div className="RPP">
                  <strong>{item.price} PKR</strong>
                </div>
              </div>

              {/* Cart Button */}
              <div className="col-2 d-flex align-items-center">
                <button className="RB mb-3">⏩</button>
              </div>

              {/* Divider */}
              <hr className="w-100" />
            </div>
          </Link>
        ))}
      </div>
      <div style={{ height: "60px" }}>&nbsp;</div>
      <Toolbar />
    </>
  );
}

export default Details;
