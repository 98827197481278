import Navbar from "../Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import Toolbar from "../ToolBar/Toolbar";
import AddressCard from "../Cards/AddressCard/AddressCard";

function MyAddress() {
  return (
    <>
      <Navbar />
      {/* section 1 */}

      <div className="container-fluid px-0 mt-2 mb-2">
        <div className="row ">
          <div className="col-10">
            <ol className="breadcrumb ">
              <li className="breadcrumb-item">
                <Link id="link" to="/More">
                  More
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link id="link" to="/MyAddress">
                  My Address
                </Link>
              </li>
            </ol>
          </div>
          <div className="col-2">
            <FontAwesomeIcon id="bell" icon={faBell} />
          </div>
        </div>

        {/* section 2 */}
        <AddressCard
          Type={"Home"}
          Address={"51/5$ Road: 7, Badda, Dhaka, Bangladesh."}
        />
        <AddressCard
          Type={"Work"}
          Address={"51/5$ Road: 7, Badda, Dhaka, Bangladesh."}
        />
        <AddressCard
          Type={"Office"}
          Address={"51/5$ Road: 7, Badda, Dhaka, Bangladesh."}
        />

        {/* section 3 */}
        <div>
          <Link className="OrderButton" to="/AddNewAddress">
            <button>Add New Address</button>
          </Link>
        </div>

        {/* section 4 */}
      </div>
      <Toolbar />
      <div style={{ height: "60px" }}></div>
    </>
  );
}

export default MyAddress;
