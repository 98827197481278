import "../Cart/Cart.css";
import Navbar from "../Navbar/Navbar";
import Toolbar from "../ToolBar/Toolbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faLocationDot,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import toast, { Toaster } from "react-hot-toast";

function Cart() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productId = parseInt(queryParams.get("product_id"));
  const [quantity, setQuantity] = useState(1); // Initialize the quantity state
  // Find product based on query param
  const product = window.Product.find((p) => p.id === productId);
  const [subtotal, setSubtotal] = useState();
  //Address card
  const [showCard, setShowCard] = useState(false);
  const [newAddress, setNewAddress] = useState("");
  const incrementQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decrementQuantity = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };
  const calculateSubtotal = () => {
    const subtotal = product.price * quantity;
    setSubtotal(subtotal);
  };
  const handleChangeLocation = () => {
    setShowCard(!showCard);
  };

  const handleSaveAddress = () => {
    setShowCard(false);
  };

  const delivery = 100;

  return (
    <>
      <Navbar />
      <div className="container-fluid px-0">
        {window.Cart.id}

        {/* Breadcrumb Section */}
        <div className="row mt-3 mb-3">
          <div className="col-12">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link id="link" to="/Home">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link id="link" to="/Cart">
                  Cart
                </Link>
              </li>
            </ol>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            {/* Cart Items */}
            {window.Cart.length > 0 ? (
              window.Cart.map((item) => (
                <div
                  key={item.id}
                  className="row CartP align-items-center mt-2"
                >
                  {/* Image Section */}
                  <div className="col-4">
                    <img
                      className="CartPI"
                      src={`assets/Product/${item.img.split(",")[0]}`}
                      alt={item.name}
                    />
                  </div>
                  {/* Product Details Section */}
                  <div className="col-8 d-flex flex-column justify-content-between">
                    <div className="row align-items-center">
                      <div className="col-8">
                        <div className="CartPN">{item.name}</div>
                      </div>
                      <div className="col-4 d-flex justify-content-between">
                        {/* Quantity Adjustment Buttons */}
                        <Link to="/Cart">
                          <button
                            class="bin-button"
                            onClick={() => {
                              const index = window.Cart.findIndex(
                                (p) => p.id === item.id
                              );
                              window.Cart.splice(index, 1);
                              toast.success("Product removed from cart.");
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashCan} />
                          </button>
                        </Link>
                      </div>
                    </div>

                    <div className="CartPOne">
                      <strike>Rs {item.price}</strike>
                    </div>
                    <div className="row align-items-center CartPP">
                      <div className="col-6">
                        <strong>{item.price}</strong>
                      </div>
                      <div className="col-6 d-flex align-items-center gap-2 CCardBtn">
                        {/* Quantity Adjustment Buttons */}
                        <button
                          className="btn btnM"
                          onClick={() => {
                            calculateSubtotal();
                            decrementQuantity();
                          }}
                        >
                          <FontAwesomeIcon icon={faMinus} />
                        </button>
                        <span>{quantity}</span>
                        <button
                          className="btn btnP"
                          onClick={() => {
                            calculateSubtotal();
                            incrementQuantity();
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Link className="AddButton my-4" to="/Pro_Page">
                      <button>Add More Products</button>
                    </Link>
                  </div>
                </div>
              ))
            ) : (
              <div>
                <h1>Cart is Empty</h1>
                <div>
                  <Link className="AddButton my-4" to="/Pro_Page">
                    <button>Add Products</button>
                  </Link>
                </div>
              </div>
            )}
          </div>

          {/* Add More Products Button */}

          {/* Delivery Date and Time */}
          <div className="row align-items-center Calandar mb-4">
            <div className="text-start">Expected Date and Time:</div>
            <div className="input-group mt-4">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faCalendar} />
              </div>
              <input
                type="date"
                className="form-control"
                defaultValue={new Date().toISOString().substr(0, 10)}
              />
            </div>
          </div>

          <div className="Time mb-4">
            {[
              "8 AM-11 AM",
              "11 AM-12 PM",
              "12 PM-2PM",
              "2 PM-4 PM",
              "4 PM-6 PM",
              "6 PM-8 PM",
            ].map((slot, index) => (
              <button key={index}>{slot}</button>
            ))}
          </div>

          {/* Delivery Location */}
          <div className="row align-items-center lochead mt-2">
            <div className="col-6 text-start">Delivery Location</div>
            <div
              className="col-6 lochead text-end"
              onClick={() => {
                handleChangeLocation();
              }}
            >
              Change location
            </div>
            {showCard && (
              <div className="card mt-3 p-3">
                <div className="card-body">
                  <h5 className="card-title">Enter New Address</h5>
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Enter your address"
                    value={newAddress}
                    onChange={(e) => setNewAddress(e.target.value)}
                  />
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-primary me-2"
                      onClick={() => {
                        toast.success("Address saved successfully!");
                        handleSaveAddress();
                      }}
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={() => setShowCard(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="row align-items-center location-row">
            <div className="col-2">
              <FontAwesomeIcon icon={faLocationDot} className="location-icon" />
            </div>
            <div className="col-8">
              <p className="location-address CLA mt-3">
                {newAddress || "32 Llanberis Close, Tonteg, CF38 1HR"}
              </p>
            </div>
          </div>

          {/* Order Summary */}
          <div className="row align-items-center subtotal mt-2">
            <div className="col-6 text-start">Sub Total</div>
            <div className="col-6 text-end">{subtotal}</div>
          </div>
          <div className="row align-items-center delivery mt-2">
            <div className="col-6 text-start">Delivery Charges</div>
            <div className="col-6 text-end">Rs{delivery}</div>
          </div>
          <div className="row align-items-center total mt-2">
            <div className="col-6 text-start">Total</div>
            <div className="col-6 text-end">Total</div>
          </div>
          <hr />

          {/* Place Order Button */}
          <div>
            <Link className="OrderButton" to="/ConfirmOrder">
              <button>Place Order</button>
            </Link>
          </div>
        </div>

        <div style={{ height: "60px" }}>&nbsp;</div>
        <Toolbar />
        <Toaster/>
      </div>
    </>
  );
}

export default Cart;
