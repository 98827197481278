import "bootstrap/dist/css/bootstrap.min.css";
import "./AddressCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons"; // Import specific icons
import { faPencil } from "@fortawesome/free-solid-svg-icons";

function AddressCard({ Type, Address, PTwo }) {
  return (
    <>
      {/* Recommended Product */}
      <div className="AddressType"> {Type}</div>
      <div className="row CartP align-items-center ">
        <div className="col-8 d-flex flex-column justify-content-center">
          <div className="CartPOne">{Address}</div>
        </div>

        <div className="col-4 d-flex align-items-center gap-2 Addressbtn">
          {/* Quantity Adjustment Buttons */}
          <button className="btnE">
            <FontAwesomeIcon icon={faPencil} />
          </button>
          <button className="btnD mx-2">
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      </div>
      <hr />
    </>
  );
}

export default AddressCard;
