import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons"; // Import specific icons
import { Link } from "react-router-dom";
import "./SearchCard.css";
function SearchCard({ ProName }) {
  return (
    <>
      {/* Order Card */}
      <div className="container-fluid">
        <div className=" row align-items-center SearchCard">
          <div className="col d-flex flex-row justify-content-start align-items-center">
            <FontAwesomeIcon icon={faSearch} className="me-2" />
            <Link
              className="text-decoration-none text-black-50 "
              to="/Pro_Page"
            >
              {ProName}
            </Link>
          </div>
        </div>
      </div>

      <hr />
    </>
  );
}

export default SearchCard;
