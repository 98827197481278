import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faUser, faKey } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { APIsignUp } from "../../../api/Signup";
import toast, { Toaster } from "react-hot-toast";

function NewAcc() {
  // Refs for form inputs
  const emailRef = useRef();
  const usernameRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // State to control button activation
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  // Helper function to validate email
  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);

  };

  // Function to check form validity
  const checkFormValidity = () => {
    const email = emailRef.current?.value || "";
    const username = usernameRef.current?.value || "";
    const password = passwordRef.current?.value || "";
    const confirmPassword = confirmPasswordRef.current?.value || "";

    const isFormValid =
      isValidEmail(email) &&
      username.trim() !== "" &&
      password.length >= 8 &&
      password === confirmPassword;

    setIsButtonEnabled(isFormValid);
  };

  // Function to handle signup
  const handleSignup = async (e) => {
    e.preventDefault(); // Prevent default form submission
    const email = emailRef.current?.value;
    const username = usernameRef.current?.value;
    const password = passwordRef.current?.value;
    const confirmPassword = confirmPasswordRef.current?.value;

    try {
      const user = await APIsignUp(email, username, password, confirmPassword);
      if (user.message) {
        toast.success(`Signup success! `,user.message);
        setTimeout(() => {

        navigate("/Email", { state: { email, username, fromPage: "NewAcc" }});
      }, 2000);}
       else {
        toast.error(`Signup failed! `,user.error);
      }
    } catch (error) {
      console.error("Error during signup:", error);
      toast.error("An error occurred during signup.");
    }
  };

  return (
    <>
      <div className="container-fluid mt-2" style={{ fontSize: "20px" }}>
        <Link to="/Home" className="text-decoration-none text-black">
          👈 Back to Home
        </Link>
      </div>
      <div className="container-fluid px-0">
        {/* Lottie Animation */}
        <div className="d-flex align-items-center justify-content-center">
          <dotlottie-player
            src="https://lottie.host/b2cf5be9-a9c9-4fc0-92a2-1122522e3524/QmQ03jesk9.json"
            background="transparent"
            speed="0.3"
            style={{ width: "250px", height: "20px" }}
            autoplay
          ></dotlottie-player>
        </div>

        {/* Signup Form */}
        <div className="container-fluid">
          <h4>Signup</h4>
          <small>Please enter your valid email, username, and password.</small>
          <form onSubmit={handleSignup}>
            {/* Email Input */}
            <div className="input-group mb-2">
              <span className="input-group-text">
                <FontAwesomeIcon icon={faGoogle} style={{ color: "#4285F4" }} />
              </span>
              <input
                type="email"
                placeholder="Enter your email"
                className="form-control"
                required
                ref={emailRef}
                onChange={checkFormValidity}
              />
            </div>

            {/* Username Input */}
            <div className="input-group mb-2">
              <span className="input-group-text">
                <FontAwesomeIcon icon={faUser} />
              </span>
              <input
                type="text"
                placeholder="Enter your username"
                className="form-control"
                required
                ref={usernameRef}
                onChange={checkFormValidity}
              />
            </div>

            {/* Password Input */}
            <div className="input-group mb-3">
              <span className="input-group-text">
                <FontAwesomeIcon icon={faKey} />
              </span>
              <input
                type="password"
                placeholder="Enter your password"
                className="form-control"
                ref={passwordRef}
                required
                minLength="8"
                onChange={checkFormValidity}
              />
            </div>

            {/* Confirm Password Input */}
            <div className="input-group mb-2">
              <span className="input-group-text">
                <FontAwesomeIcon icon={faKey} />
              </span>
              <input
                type="password"
                placeholder="Confirm your password"
                className="form-control"
                ref={confirmPasswordRef}
                required
                minLength="8"
                onChange={checkFormValidity}
              />
            </div>

            {/* Submit Button */}
            <button
              className="Submit btn btn-primary "
              disabled={!isButtonEnabled}
              type="submit"
            >
              Next
            </button>
            <Toaster toastOptions={{ duration: 2000 }}/>
          </form>
        </div>
      </div>
      <div style={{ height: "60px" }}></div>
    </>
  );
}

export default NewAcc;
