export const APIotp = async (email,otp,token,toPage=false) => {
    const myHeaders = new Headers();
    myHeaders.append("app", "haleeb");
    myHeaders.append("callsign", "otp");
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  
    const urlencoded = new URLSearchParams();
    urlencoded.append("email", email);
    urlencoded.append("otp",otp);
    urlencoded.append("token",token);
    if(toPage==="NewPass")urlencoded.append("forgetpass", "NewPass");

  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
    };
  
    const response = await fetch("https://api.fourelementx.com", requestOptions)
      .then((response) => response?.json())
      .then(result => {
      return result
  })
      .catch(error => error);
  
    return response;
  };
  