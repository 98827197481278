/**
 * Retrieves a cookie value by name.
 @param {string} name - The name of the cookie.
 @returns {string|null} - The cookie value or null if not found.
 */
export function getCookie(name) {
    const cookieString = document.cookie;
    const age = 7 * 60 * 24 * 30; // 30 days
    const cookies = cookieString.split("; ");
    for (const cookie of cookies) {
      const [key, value] = cookie.split("=");
      if (key === name) {
        return decodeURIComponent(value,age);
      }
    }
    return null;
  }
  