import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "./C_Card.css";
function C_Card({ imgSrc, alttext, text }) {
  return (
    <>
      {/* Wrap the Product card with Link for navigaton */}

      <div className="col-6 justify-content-center CCard">
        <img src="./assets/CBG.png" className="lineartC1" alt="" />
        <img src="./assets/CBG.png" className="lineartC2" alt="" />
        <img src="./assets/CBG.png" className="lineartC3" alt="" />
        <img src="./assets/CBG.png" className="lineartC4" alt="" />

        <div className="Product my-1 p-3 pb-2">
          <img className="CImg " src={imgSrc} alt={alttext} />
          <p className="CName">{text}</p>
        </div>
      </div>
    </>
  );
}

export default C_Card;
