import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const StarRating = () => {
  const [rating, setRating] = useState(0); // State to store the user's rating

  // Function to handle star click
  const handleStarClick = (index) => {
    // If the user clicks on the already selected star, reset the rating (toggle)
    if (rating === index + 1) {
      // Clear the rating
        setRating(0); 
    } else {
        // Set the rating based on the clicked star
      setRating(index + 1); 
    }
  };

  return (
    <div className="Stars">
      {[...Array(5)].map((_, index) => (
        <FontAwesomeIcon
          key={index}
          icon={faStar}
          className={index < rating ? "StarActive" : ""}
          onClick={() => handleStarClick(index)} // Call the click handler
        />
      ))}
    </div>
  );
};

export default StarRating;
